import Container, { INPUT_SCHEME } from '../components/Container'
import React, { InputHTMLAttributes, forwardRef } from 'react'

import Icon from 'library/components/Icon'
import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type SelectBaseProps = Pick<
  InputHTMLAttributes<HTMLSelectElement>,
  'id' | 'onChange' | 'onBlur' | 'onFocus' | 'disabled' | 'placeholder' | 'autoFocus' | 'autoComplete'
>

type SelectedOption = {
  label: string
  value: string
}

type Props = SelectBaseProps & {
  className?: string
  scheme?: SchemeType
  name: string
  value?: string
  options: SelectedOption[]
}

const Select = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const { name, className, scheme, options, disabled, ...otherProps } = props

  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const iconColor = colorScheme === 'light' ? 'charcoal' : 'white'

  return (
    <InputContainer
      className={className}
      scheme={colorScheme}
      disabled={!!disabled}
      $scheme={colorScheme}
      $disabled={!!disabled}
      $noValue={!otherProps.value}
    >
      <Input
        ref={ref}
        name={name}
        $scheme={colorScheme}
        $disabled={!!disabled}
        $noValue={!otherProps.value}
        disabled={!!disabled}
        {...otherProps}
      >
        {otherProps.placeholder && (
          <option value='' disabled>
            {props.placeholder}
          </option>
        )}

        {options.map((option) => (
          <option key={`${name}-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>

      <IconButton>
        <Icon name='caret-down-small' color={iconColor} />
      </IconButton>
    </InputContainer>
  )
})

Select.displayName = 'Select'

export default Select

type StyledSelectProps = {
  $scheme: SchemeType
  $disabled: boolean
  $noValue: boolean
}

const Input = styled.select<StyledSelectProps>`
  all: unset;
  flex: 1;
  width: 0;
  height: 100%;
  align-items: center;
  padding: 0 28px 0 12px;
  user-select: none;
  color: ${({ $scheme, $disabled, theme }) =>
    theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'color']]};
  color: ${({ $scheme, $disabled, $noValue, theme }) =>
    $noValue && theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'placeholderColor']]};
  background: transparent;
  transition: ${({ theme }) => theme.transitions.background};
  ${({ theme }) => theme.typography['body-small-400'].mobile};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 28px 0 16px;
    ${({ theme }) => theme.typography['body-small-400'].desktop}
  }
`

const IconButton = styled.div`
  position: absolute;
  right: 8px;
  pointer-events: none;
`

const InputContainer = styled(Container)<StyledSelectProps>`
  align-items: center;
  position: relative;

  ${Input}:not(:placeholder-shown, :focus, :active) {
    background: ${({ $scheme, $noValue, $disabled, theme }) =>
      !$disabled && !$noValue && theme.colors[INPUT_SCHEME[$scheme].activeBackground]};
  }
`
