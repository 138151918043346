import { FlagImage } from 'react-international-phone'
import Flex from 'library/components/Flex'
import Icon from 'library/components/Icon'
import React from 'react'
import { SelectOption } from '../Tel'
import Text from 'library/components/Text'
import styled from 'styled-components'

type Props = {
  item: SelectOption
  value: string
  onChange: (option: SelectOption) => void
}

const Item = ({ item, value, onChange }: Props) => (
  <Wrapper>
    <Container
      id={item.id}
      $ticked={value === item.value}
      onClick={() => {
        onChange(item)
      }}
    >
      <FlagImage iso2={item.iso2} size='22px' />

      <Flex gap={12} flexGrow={1}>
        <Flex flexGrow={1} gap={8} center='vertically'>
          <Text type='body-small-400' color='charcoal'>
            {item.label}
          </Text>

          {item.dialCode && (
            <Text type='body-small-400' color='charcoal60'>
              +{item.dialCode}
            </Text>
          )}
        </Flex>

        {value === item.value && <Icon name='tick-blue' />}
      </Flex>
    </Container>
  </Wrapper>
)

export default Item

const Wrapper = styled.div`
  width: 100%;
`

const Container = styled.div<{
  $ticked: boolean
}>`
  width: 100%;
  height: 36px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
  user-select: none;
  background: ${({ theme, $ticked }) => ($ticked ? theme.colors.charcoal5 : theme.colors.white)};
  transition: ${({ theme }) => theme.transitions.background};

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 4px 8px;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.charcoal5};
  }

  &:active {
    background: ${({ theme }) => theme.colors.charcoal10};
  }
`
