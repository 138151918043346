import { BreakpointType, breakpointNumbers } from 'theme'
import { useEffect, useState } from 'react'

import { useMediaQuery } from 'react-responsive'

const useBreakpoint = () => {
  const [activeBreakpoint, setActiveBreakpoint] = useState<BreakpointType>('xl') // Default to 'xl'

  // Convert em values to pixels assuming 1em = 16px
  const pxBreakpoints = Object.fromEntries(Object.entries(breakpointNumbers).map(([key, value]) => [key, value * 16]))

  const isXs = useMediaQuery({ maxWidth: pxBreakpoints.sm - 1 }) // Extra small devices, above 375px.
  const isSm = useMediaQuery({ minWidth: pxBreakpoints.sm - 1, maxWidth: pxBreakpoints.md - 1 }) //  Small devices, above 744px.
  const isMd = useMediaQuery({ minWidth: pxBreakpoints.md - 1, maxWidth: pxBreakpoints.lg - 1 }) // Medium devices, above 1024px.
  const isLg = useMediaQuery({ minWidth: pxBreakpoints.lg - 1, maxWidth: pxBreakpoints.xl - 1 }) // Large devices, above 1280px.
  const isXl = useMediaQuery({ minWidth: pxBreakpoints.xl }) // Extra large devices, above 1560px.

  useEffect(() => {
    const breakpoint = setTimeout(() => {
      if (isXs) setActiveBreakpoint('xs')
      else if (isSm) setActiveBreakpoint('sm')
      else if (isMd) setActiveBreakpoint('md')
      else if (isLg) setActiveBreakpoint('lg')
      else if (isXl) setActiveBreakpoint('xl')
    }, 0)

    return () => clearTimeout(breakpoint)
  }, [isXs, isSm, isMd, isLg, isXl])

  return activeBreakpoint
}

export default useBreakpoint
