import { ColorType, SchemeType } from 'theme'
import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react'

import Icon from '../../Icon'
import Text from '../../Text'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type BaseProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur' | 'onFocus' | 'value' | 'autoFocus' | 'checked' | 'defaultChecked'
>

type CheckboxVariant = 'radio' | 'check'

type Props = BaseProps & {
  className?: string
  scheme?: SchemeType
  id: string
  name: string
  label: ReactNode
  error?: boolean
  variant?: CheckboxVariant
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className, scheme, label, error, variant = 'check', ...otherProps }, ref) => {
    const _scheme = useBlockScheme()

    const colorScheme = scheme || _scheme

    const textType = variant === 'radio' ? 'body-small-400' : 'h5'
    const labelColor =
      variant === 'radio' && otherProps.checked
        ? CHECKBOX_COLOR_SCHEME[colorScheme]['radio'].activeColor
        : CHECKBOX_COLOR_SCHEME[colorScheme][variant].textColor

    return (
      <CheckboxContainer className={className}>
        <Check $scheme={colorScheme} $hasError={error} $variant={variant} $checked={otherProps.checked} tabIndex={0}>
          <HiddenCheckbox
            ref={ref}
            type='checkbox'
            $scheme={colorScheme}
            $variant={variant}
            tabIndex={-1}
            {...otherProps}
          />
          {variant === 'check' && <Icon name='tick' color='white' />}
          {variant === 'radio' && <RadioFill $scheme={colorScheme} />}
        </Check>

        <Label htmlFor={otherProps.id} $variant={variant}>
          <Text type={textType} color={labelColor}>
            {label}
          </Text>
        </Label>
      </CheckboxContainer>
    )
  },
)

Checkbox.displayName = 'Checkbox'

export default Checkbox

type VariantPropsType = {
  [S in CheckboxVariant]: {
    borderRadius: number
    paddingTop: number
    afterContentBorderRadius: number
  }
}

type CheckboxSchemeType = {
  [S in SchemeType]: {
    [T in CheckboxVariant]: {
      textColor: ColorType
      background: ColorType
      activeColor: ColorType
      inactiveBorder: ColorType
      inactiveHoverBorder: ColorType
      hoverBackground: ColorType
      hoverBorder: ColorType
      errorBorder: ColorType
    }
  }
}

export const CHECKBOX_VARIANT_PROPS: VariantPropsType = {
  check: {
    borderRadius: 4,
    paddingTop: 5,
    afterContentBorderRadius: 8,
  },
  radio: {
    borderRadius: 100,
    paddingTop: 3,
    afterContentBorderRadius: 100,
  },
}

const CHECKBOX_COLOR_SCHEME: CheckboxSchemeType = {
  light: {
    check: {
      textColor: 'charcoal80',
      background: 'white',
      activeColor: 'blue',
      inactiveBorder: 'charcoal60Solid',
      inactiveHoverBorder: 'charcoal',
      hoverBackground: 'charcoal5',
      hoverBorder: 'charcoal60Solid',
      errorBorder: 'errorLight',
    },
    radio: {
      textColor: 'charcoal80Solid',
      background: 'white',
      activeColor: 'blue',
      inactiveBorder: 'charcoal60',
      inactiveHoverBorder: 'charcoal',
      hoverBackground: 'charcoal5',
      hoverBorder: 'charcoal',
      errorBorder: 'errorLight',
    },
  },
  dark: {
    check: {
      textColor: 'white80',
      background: 'navyDark',
      activeColor: 'aquamarine',
      inactiveBorder: 'white60',
      inactiveHoverBorder: 'white',
      hoverBackground: 'white10',
      hoverBorder: 'white60',
      errorBorder: 'errorDark',
    },
    radio: {
      textColor: 'white80',
      background: 'navyDark',
      activeColor: 'aquamarine',
      inactiveBorder: 'white60',
      inactiveHoverBorder: 'white',
      hoverBackground: 'white10',
      hoverBorder: 'white',
      errorBorder: 'errorLight',
    },
  },
}

type StyledCheckboxProps = {
  $scheme: SchemeType
  $variant: CheckboxVariant
  $checked?: boolean
  $hasError?: boolean
}

const CheckboxContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  user-select: none;
`

const HiddenCheckbox = styled.input<{ $scheme: SchemeType; $variant: CheckboxVariant }>`
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  border-radius: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].borderRadius}px;
`

const RadioFill = styled.div<{ $scheme: SchemeType }>`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: ${({ $scheme, theme }) => theme.colors[CHECKBOX_COLOR_SCHEME[$scheme]['radio'].background]};
`

const Check = styled.div<StyledCheckboxProps>`
  width: 20px;
  height: 20px;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border-radius: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].borderRadius}px;
  border: 1.5px solid;
  border-color: ${({ $scheme, $variant, theme }) =>
    theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].inactiveBorder]};
  background: transparent;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  outline: none;

  :hover {
    border-color: ${({ $scheme, $checked, $variant, theme }) =>
      $checked
        ? theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].activeColor]
        : theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].inactiveHoverBorder]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px;
    border: 2px solid
      ${({ $scheme, $variant, theme }) => theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].activeColor]};
    border-radius: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].afterContentBorderRadius}px;
  }

  :has(~ label:hover) {
    ::after {
      opacity: 1;
    }

    border-color: ${({ $scheme, $variant, $checked, theme }) =>
      $checked
        ? theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].activeColor]
        : theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].inactiveHoverBorder]};
  }

  :has(:checked) {
    border: 1.5px solid
      ${({ $scheme, $variant, theme }) => theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].activeColor]};

    ${RadioFill} {
      background-color: ${({ $scheme, theme }) => theme.colors[CHECKBOX_COLOR_SCHEME[$scheme]['radio'].activeColor]};
    }

    background: ${({ $variant, theme }) => $variant === 'check' && theme.colors.blue};

    svg {
      visibility: visible;
    }
  }

  :hover::after {
    opacity: 1;
  }

  ::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 14px;
    border-radius: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].afterContentBorderRadius}px;
    border: 1.25px solid
      ${({ $scheme, $variant, $hasError, theme }) =>
        $hasError ? theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].errorBorder] : `transparent`};
  }

  ::after {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 1;
    border-radius: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].afterContentBorderRadius}px;
    pointer-events: none;
    background: ${({ $scheme, $variant, theme }) =>
      theme.colors[CHECKBOX_COLOR_SCHEME[$scheme][$variant].hoverBackground]};
    opacity: 0;
    transition: ${({ theme }) => theme.transitions.checkbox};
  }

  svg {
    position: relative;
    z-index: 3;
    visibility: hidden;
  }
`

const Label = styled.label<{ $variant: CheckboxVariant }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  padding-top: ${({ $variant }) => CHECKBOX_VARIANT_PROPS[$variant].paddingTop}px;
  user-select: none;
`
