import Container, { INPUT_SCHEME } from '../components/Container'
import React, { InputHTMLAttributes, forwardRef } from 'react'

import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type BaseProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'id'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'onKeyPress'
  | 'onKeyDown'
  | 'disabled'
  | 'value'
  | 'placeholder'
  | 'autoFocus'
  | 'autoComplete'
  | 'autoCapitalize'
>

type Props = BaseProps & {
  className?: string
  scheme?: SchemeType
  name: string
}

const Text = forwardRef<HTMLInputElement, Props>(({ name, className, scheme, disabled, ...otherProps }, ref) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme

  return (
    <InputContainer
      className={className}
      scheme={colorScheme}
      disabled={!!disabled}
      $scheme={colorScheme}
      $disabled={!!disabled}
    >
      <Input
        ref={ref}
        type='text'
        name={name}
        $scheme={colorScheme}
        $disabled={!!disabled}
        disabled={!!disabled}
        {...otherProps}
      />
    </InputContainer>
  )
})

Text.displayName = 'Text'

export default Text

type StyledTextProps = {
  $scheme: SchemeType
  $disabled: boolean
}

const Input = styled.input<StyledTextProps>`
  all: unset;
  flex: 1;
  width: 0;
  height: 100%;
  padding: 0 12px;
  color: ${({ $scheme, theme }) => theme.colors[INPUT_SCHEME[$scheme].color]};
  color: ${({ $scheme, $disabled, theme }) => $disabled && theme.colors[INPUT_SCHEME[$scheme].disabledColor]};
  background: transparent;
  transition: ${({ theme }) => theme.transitions.background};
  ${({ theme }) => theme.typography['body-small-400'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 16px;
    ${({ theme }) => theme.typography['body-small-400'].desktop}
  }

  ::placeholder {
    color: ${({ $scheme, $disabled, theme }) =>
      theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'placeholderColor']]};
  }

  ::-ms-input-placeholder {
    color: ${({ $scheme, $disabled, theme }) =>
      theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'placeholderColor']]};
  }
`

const InputContainer = styled(Container)<StyledTextProps>`
  ${Input}:not(:placeholder-shown, :focus, :active) {
    background: ${({ $scheme, $disabled, theme }) =>
      !$disabled && theme.colors[INPUT_SCHEME[$scheme].activeBackground]};
  }
`
