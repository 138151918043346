import { Placement, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react'
import React, { ReactElement, ReactNode, forwardRef, useCallback, useRef, useState } from 'react'

import DropdownContext from './DropdownContext'
import FloatingPortalProvider from './FloatingPortalProvider'
import { size } from '@floating-ui/dom'
import styled from 'styled-components'
import useOnClickOutside from 'hooks/useOnClickOutside'

const triggers = ['click', 'hover'] as const

type TriggerProps = typeof triggers[number]

type Props = {
  children: ReactElement
  content: ReactNode
  placement?: Placement
  offset?: [number, number]
  matchParentWidth?: boolean
  trigger?: TriggerProps
  initialOpen?: boolean
  onOpen?: (isOpen: boolean) => void
  onHide?: () => void
  disablePortal?: boolean
  stayOpenOnClick?: boolean
  width?: number
}

const Dropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      content,
      placement = 'bottom-start',
      offset: offsetProp = [0, 8],
      matchParentWidth = false,
      trigger = 'click',
      initialOpen = false,
      onOpen,
      onHide = () => null,
      disablePortal,
      stayOpenOnClick,
      width,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const clickAwayRef = useRef<HTMLDivElement>(null)

    const [showDropdown, setShowDropdown] = useState(initialOpen)
    const [refWidth, setRefWidth] = useState(0)

    const openDropdown = () => {
      onOpen?.(true)
      setShowDropdown(true)
    }

    const closeDropdown = () => {
      onHide?.()
      setShowDropdown(false)
    }

    useOnClickOutside(clickAwayRef, (e) => {
      if (!disablePortal) {
        const portal = document.getElementById('dropdown-portal')

        if (portal?.contains(e.target as Node)) {
          return
        }
      }

      if (containerRef.current?.contains(e.target as Node)) {
        return
      }

      closeDropdown()
    })

    const { x, y, refs, strategy } = useFloating({
      middleware: [
        offset({ crossAxis: offsetProp[0], mainAxis: offsetProp[1] }),
        flip(),
        shift(),
        size({
          apply({ rects }) {
            setRefWidth(rects.reference.width)
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
      placement,
    })

    const onClickTarget = () => {
      children?.props?.onClick?.()

      if (trigger === 'click') {
        if (showDropdown && !stayOpenOnClick) {
          closeDropdown()
        } else {
          openDropdown()
        }
      }
    }

    const onMouseEnter = useCallback(() => {
      if (trigger === 'hover') {
        setTimeout(() => openDropdown(), 0)
      }
    }, [showDropdown])

    const onMouseLeave = useCallback(() => {
      if (trigger === 'hover') {
        closeDropdown()
      }
    }, [showDropdown])

    return (
      <DropdownContext.Provider
        value={{ isOpen: showDropdown, open: () => openDropdown(), close: () => closeDropdown() }}
      >
        <div ref={containerRef}>
          <div ref={ref}>
            {React.cloneElement(children, {
              ref: refs.setReference,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave,
              onClick: onClickTarget,
            })}
          </div>
        </div>

        <FloatingPortalProvider disablePortal={disablePortal} showDropdown={showDropdown}>
          <Wrapper ref={clickAwayRef}>
            <DropdownContainer
              ref={refs.setFloating}
              $referenceWidth={refWidth}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              $matchParentWidth={matchParentWidth}
              $width={width}
            >
              <Overlay $matchParentWidth={matchParentWidth || !!width}>{content}</Overlay>
            </DropdownContainer>
          </Wrapper>
        </FloatingPortalProvider>
      </DropdownContext.Provider>
    )
  },
)

Dropdown.displayName = 'Dropdown'

export default Dropdown

const Wrapper = styled.div`
  position: relative;
`

const DropdownContainer = styled.div<{ $matchParentWidth: boolean; $referenceWidth: number; $width?: number }>`
  width: ${({ $matchParentWidth, $referenceWidth }) => $matchParentWidth && `${$referenceWidth}px`};
  width: ${({ $width }) => $width && `${$width}px`};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const Overlay = styled.div<{ $matchParentWidth: boolean }>`
  width: ${({ $matchParentWidth }) => ($matchParentWidth ? '100%' : 'fit-content')};
  height: 254px;
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.charcoal10};
  box-shadow: 0px 25px 45px ${({ theme }) => theme.colors.charcoal20};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`
