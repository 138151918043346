import Container, { INPUT_SCHEME } from '../components/Container'
import React, { InputHTMLAttributes, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { defaultCountries, parseCountry, usePhoneInput } from 'react-international-phone'

import CountrySelectorRenderer from './components/CountrySelectorRenderer'
import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type BaseProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'id' | 'onKeyPress' | 'onKeyDown' | 'disabled' | 'placeholder' | 'autoFocus' | 'autoComplete'
>

export type SelectOption = {
  id: string
  iso2: string
  label: string
  value: string
  dialCode: string
}

type Props = BaseProps & {
  className?: string
  scheme?: SchemeType
  name: string
  value?: string
  withCountrySelector?: boolean
  onChange?: (e: ChangeEvent, value: string) => void
  onBlur?: (e: BlurEvent) => void
  onFocus?: (e: FocusEvent) => void
}

export type TelInstanceMethods = {
  focus: () => void
  blur: () => void
}

const Tel = forwardRef<TelInstanceMethods, Props>(
  (
    { name, className, scheme, withCountrySelector = false, disabled, onChange, onFocus, onBlur, ...otherProps },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState<string | undefined>(otherProps?.value)
    const [iso2Code, setIso2Code] = useState('gb')

    const valueRef = useRef(value)

    const _scheme = useBlockScheme()

    const formatPhoneNumber = (phone: string) => {
      if (iso2Code === 'gb') {
        return phone.replace(/(\+44)0/, '$1')
      }

      return phone
    }

    const { inputRef, inputValue, handlePhoneValueChange, setCountry } = usePhoneInput({
      disableDialCodePrefill: true,
      forceDialCode: withCountrySelector,
      value: valueRef.current,
      countries: defaultCountries,
      defaultCountry: 'gb',
      onChange: (data) => {
        valueRef.current = formatPhoneNumber(data.phone)
        setValue(data.inputValue)
        onChange?.(
          {
            type: 'change',
            target: { name, value: data.inputValue },
          } as ChangeEvent,
          formatPhoneNumber(data?.phone),
        )
        setIso2Code(data.country.iso2)
      },
    })

    const focus = () => {
      onFocus?.({
        type: 'focus',
        target: { name, value: valueRef.current as string },
      })
    }

    const blur = () => {
      onBlur?.({
        type: 'blur',
        target: { name, value: valueRef.current as string },
      })
    }

    useImperativeHandle(
      ref,
      () => ({
        get value() {
          return value
        },
        set value(newValue: string | undefined) {
          setValue(newValue)
          valueRef.current = newValue
        },
        focus,
        blur,
      }),
      [value],
    )

    const colorScheme = scheme || _scheme

    const countries = useMemo(() => {
      return defaultCountries.map((c) => {
        const country = parseCountry(c)

        return {
          id: country.iso2,
          value: country.iso2,
          label: country.name,
          iso2: country.iso2,
          dialCode: country.dialCode,
        }
      })
    }, [])

    const handleChangeCountry = (option: SelectOption) => {
      setIso2Code(option.iso2)
      setCountry(option.iso2)

      setIsOpen(false)
      inputRef.current?.focus()
    }

    const hasPhoneNumber = useMemo(() => {
      const phoneRegex = /^\+\d+\s[^\s].*$/

      return phoneRegex.test(inputValue)
    }, [inputValue])

    return (
      <InputContainer
        className={className}
        scheme={colorScheme}
        disabled={!!disabled}
        $scheme={colorScheme}
        $disabled={!!disabled}
        $hasValue={withCountrySelector ? hasPhoneNumber : !!value}
        $isOpen={isOpen}
      >
        {withCountrySelector && (
          <CountrySelectorRenderer
            scheme={colorScheme}
            options={countries}
            value={iso2Code}
            onClick={(open) => setIsOpen(open)}
            onChange={handleChangeCountry}
          />
        )}

        <Input
          ref={inputRef}
          type='tel'
          name={name}
          value={inputValue}
          $scheme={colorScheme}
          $disabled={!!disabled}
          disabled={!!disabled}
          onChange={handlePhoneValueChange}
          {...otherProps}
        />
      </InputContainer>
    )
  },
)

Tel.displayName = 'Tel'

export default Tel

type StyledTextProps = {
  $scheme: SchemeType
  $disabled: boolean
}

type InputContainerProps = {
  $scheme: SchemeType
  $disabled: boolean
  $hasValue: boolean
  $isOpen: boolean
}

const Input = styled.input<StyledTextProps>`
  all: unset;
  flex: 1;
  width: 0;
  height: 100%;
  padding: 0 12px;
  color: ${({ $scheme, theme }) => theme.colors[INPUT_SCHEME[$scheme].color]};
  color: ${({ $scheme, $disabled, theme }) => $disabled && theme.colors[INPUT_SCHEME[$scheme].disabledColor]};
  ${({ theme }) => theme.typography['body-small-400'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 16px;
    ${({ theme }) => theme.typography['body-small-400'].desktop}
  }

  ::placeholder {
    color: ${({ $scheme, $disabled, theme }) =>
      theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'placeholderColor']]};
  }

  ::-ms-input-placeholder {
    color: ${({ $scheme, $disabled, theme }) =>
      theme.colors[INPUT_SCHEME[$scheme][$disabled ? 'disabledColor' : 'placeholderColor']]};
  }
`

const InputContainer = styled(Container)<InputContainerProps>`
  background: ${({ $scheme, $hasValue, $disabled, $isOpen, theme }) =>
    !$disabled && !$isOpen && $hasValue && theme.colors[INPUT_SCHEME[$scheme].activeBackground]};
  transition: ${({ theme }) => theme.transitions.background};
  border: ${({ $scheme, theme, $isOpen }) =>
    $isOpen && `2px solid ${theme.colors[INPUT_SCHEME[$scheme].activeBorder]}`};

  :hover {
    border: ${({ $scheme, theme, $isOpen }) =>
      $isOpen && `2px solid ${theme.colors[INPUT_SCHEME[$scheme].activeBorder]}`};
  }

  :focus-within {
    background: transparent;
  }
`

type Event = {
  type: string
  target: {
    name: string | undefined
    value: string | null
  }
}

export type BlurEvent = Event & { type: 'blur' }
export type FocusEvent = Event & { type: 'focus' }
export type ChangeEvent = Event & { type: 'change' }
