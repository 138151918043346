import { SchemeType, ThemeType } from 'theme'
import styled, { css } from 'styled-components'

import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useBlockScheme } from '../Block'
import useBrowserType from 'hooks/useBrowserType'

type TextType = keyof ThemeType['typography']

type TextColor = keyof ThemeType['colors']

type TextAlign = 'left' | 'right' | 'center' | 'justify'

type Props = {
  className?: string
  tag?: 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  type?: TextType
  color?: TextColor
  withMarkdown?: boolean
  align?: TextAlign
  tabIndex?: number
  children: React.ReactNode
}

const Text = ({ className, tag, type, color, withMarkdown = false, align, tabIndex, children }: Props) => {
  const browserType = useBrowserType()
  const scheme = useBlockScheme()

  const _color: TextColor = color || (scheme === 'dark' ? 'white' : 'charcoal')

  return (
    <TextWrapper
      className={className}
      as={type === 'with-quote' ? 'blockquote' : tag}
      tabIndex={tabIndex}
      $scheme={scheme}
      $type={type}
      $color={_color}
      $align={align}
      $isSafari={browserType === 'Safari'}
    >
      {withMarkdown && (
        <ReactMarkdown allowedElements={['strong']} unwrapDisallowed>
          {children as string}
        </ReactMarkdown>
      )}
      {!withMarkdown && children}
    </TextWrapper>
  )
}

export default Text

type StyledDivProps = {
  $scheme: SchemeType
  $type?: TextType
  $color?: TextColor
  $align?: TextAlign
  $isSafari: boolean
}

const TextWrapper = styled.div<StyledDivProps>`
  text-align: ${({ $align }) => $align};
  color: ${({ $color, theme }) => $color && theme.colors[$color]};

  strong {
    color: ${({ theme }) => theme.colors.blue};
  }

  a {
    text-decoration: underline;

    :focus-visible {
      outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    }
  }

  ${({ $type, theme }) => $type && theme.typography[$type].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ $type, theme }) => $type && theme.typography[$type].desktop}
  }

  /* With quotes config */
  ${({ $isSafari, $type }) => {
    if (!$isSafari && $type === 'with-quote') {
      return css`
        text-indent: -0.4rem;
        padding-left: -0.4rem;

        @media ${({ theme }) => theme.breakpoints.md} {
          text-indent: -0.5rem;
          padding-left: -0.5rem;
        }
      `
    } else {
      return css`
        hanging-punctuation: first last;
      `
    }
  }}

  quotes: '“' '”' '‘' '’';

  &::before {
    content: ${({ $type }) => $type === 'with-quote' && 'open-quote'};
  }
  &::after {
    content: ${({ $type }) => $type === 'with-quote' && 'close-quote'};
  }
`
