import React, { useMemo } from 'react'

import Item from './Item'
import { SelectOption } from '../Tel'
import styled from 'styled-components'
import { useDropdown } from 'library/components/Dropdown'

type Props = {
  value: string
  options: SelectOption[]
  onChange: (option: SelectOption) => void
}

const CountrySelectorList = ({ options, value, onChange }: Props) => {
  const dropdown = useDropdown()

  const commonCountries = useMemo(() => {
    const values = ['gb', 'ie', 'us']

    const filteredItems = options.filter((item) => {
      return values.some((value) => value.includes(item.iso2))
    })

    const sortedItems = filteredItems.sort((a, b) => {
      const indexA = values.indexOf(a.iso2)
      const indexB = values.indexOf(b.iso2)
      return indexA - indexB
    })

    return sortedItems
  }, [options])

  const availableCountries = useMemo(() => {
    const selectedIso2Set = new Set(commonCountries.map((selected) => selected.iso2))

    return options.filter((country) => !selectedIso2Set.has(country.iso2))
  }, [options, commonCountries])

  const handleChange = (option: SelectOption) => {
    onChange(option)
    dropdown.close()
  }

  const renderItem = (item: SelectOption) => (
    <Row key={`option-${item.iso2}`}>
      <Item item={item} value={value} onChange={handleChange} />
    </Row>
  )

  return (
    <Container>
      {commonCountries.length > 0 && <CommonCountries>{commonCountries.map(renderItem)}</CommonCountries>}

      <ListContainer>
        <ListWrapper>{availableCountries.map(renderItem)}</ListWrapper>
      </ListContainer>
    </Container>
  )
}

export default CountrySelectorList

const Container = styled.div`
  overflow: hidden auto;
  height: 530px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 254px;
  }
`

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 8px 0 8px;
  height: fit-content;
  overflow: hidden;
`

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const CommonCountries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 8px 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal10};
`

const Row = styled.div`
  :last-child {
    margin-bottom: 8px;
  }
`
