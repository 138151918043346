import { createContext, useContext } from 'react'

type DropdownServiceContext = {
  isOpen: boolean
  open: () => void
  close: () => void
}

const DropdownContext = createContext<DropdownServiceContext>({ isOpen: false, open: () => null, close: () => null })

export const useDropdown = () => useContext(DropdownContext)

export default DropdownContext
