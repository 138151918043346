import { FloatingPortal } from '@floating-ui/react'
import React from 'react'

type Props = {
  disablePortal?: boolean
  showDropdown?: boolean
  children: JSX.Element | JSX.Element[]
}

const FloatingPortalProvider = ({ disablePortal, showDropdown, children }: Props) =>
  disablePortal ? (
    <>{showDropdown && children}</>
  ) : (
    <FloatingPortal id='dropdown-portal'>{showDropdown && children}</FloatingPortal>
  )

export default FloatingPortalProvider
