import { ColorType, SchemeType } from 'theme'
import React, { ReactNode } from 'react'

import styled from 'styled-components'

type Props = {
  className?: string
  scheme: SchemeType
  disabled: boolean
  children: ReactNode
}

const Container = ({ className, scheme, disabled, children }: Props) => (
  <Wrapper className={className} $scheme={scheme} $disabled={disabled}>
    {children}
  </Wrapper>
)

export default Container

type InputSchemeType = {
  [S in SchemeType]: {
    idleBorder: ColorType
    hoverBorder: ColorType
    activeBorder: ColorType
    disabledBorder: ColorType
    errorBorder: ColorType
    activeBackground: ColorType
    color: ColorType
    disabledColor: ColorType
    placeholderColor: ColorType
    buttonBackgroundColor: ColorType
  }
}

export const INPUT_SCHEME: InputSchemeType = {
  light: {
    idleBorder: 'charcoal40',
    hoverBorder: 'charcoal',
    activeBorder: 'blue',
    disabledBorder: 'charcoal20',
    errorBorder: 'red',
    activeBackground: 'paleBlue',
    color: 'charcoal',
    disabledColor: 'charcoal40',
    placeholderColor: 'charcoal60',
    buttonBackgroundColor: 'charcoal5',
  },
  dark: {
    idleBorder: 'white40',
    hoverBorder: 'white',
    activeBorder: 'aquamarine',
    disabledBorder: 'white20',
    errorBorder: 'red',
    activeBackground: 'aquamarine20',
    color: 'white',
    disabledColor: 'white40',
    placeholderColor: 'white60',
    buttonBackgroundColor: 'white10',
  },
}

type StyledInputProps = {
  $scheme: SchemeType
  $disabled: boolean
}

const Wrapper = styled.div<StyledInputProps>`
  width: 100%;
  height: 44px;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  border: ${({ $scheme, theme }) => `1px solid ${theme.colors[INPUT_SCHEME[$scheme].idleBorder]}`};
  border: ${({ $scheme, $disabled, theme }) =>
    $disabled && ` 1px solid  ${theme.colors[INPUT_SCHEME[$scheme].disabledBorder]}`};
  transition: ${({ theme }) => theme.transitions['input-border']};
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  user-select: ${({ $disabled }) => $disabled && 'none'};

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 48px;
  }

  :hover {
    border: ${({ $scheme, theme }) => `1px solid ${theme.colors[INPUT_SCHEME[$scheme].hoverBorder]}`};
  }

  :focus-within {
    border: ${({ $scheme, theme }) => `2px solid ${theme.colors[INPUT_SCHEME[$scheme].activeBorder]}`};
    transition: none;
  }
`
