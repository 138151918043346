import React, { useState } from 'react'

import CountrySelectorList from './CountrySelectorList'
import Dropdown from 'library/components/Dropdown'
import { FlagImage } from 'react-international-phone'
import { INPUT_SCHEME } from '../../components/Container'
import Icon from 'library/components/Icon'
import Modal from 'library/components/Modal/Modal'
import { SchemeType } from 'theme'
import { SelectOption } from '../Tel'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  scheme: SchemeType
  value: string
  options: SelectOption[]
  onClick: (isOpen: boolean) => void
  onChange: (option: SelectOption) => void
}

const CountrySelectorRenderer = ({ scheme, value, options, onChange, onClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'xs'

  const handleOpen = () => {
    setIsOpen(true)
    onClick(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    onClick(false)
  }

  const handleChange = (option: SelectOption) => {
    onChange(option)
    onClick(false)
    setIsOpen(false)
  }

  return (
    <>
      {!isMobile && (
        <Dropdown
          onOpen={() => handleOpen()}
          onHide={() => handleClose()}
          width={400}
          initialOpen={isOpen}
          content={<CountrySelectorList options={options} value={value} onChange={handleChange} />}
        >
          <DisplayContainer $scheme={scheme}>
            <FlagImage iso2={value} width='22px' />

            <IconContainer $scheme={scheme} $isOpen={isOpen}>
              <Icon
                name={isOpen ? 'caret-up-small' : 'caret-down-small'}
                color={scheme === 'dark' ? 'white' : 'charcoal'}
              />
            </IconContainer>
          </DisplayContainer>
        </Dropdown>
      )}

      {isMobile && (
        <DisplayContainer $scheme={scheme} onClick={() => setIsOpen(true)}>
          <FlagImage iso2={value} width='22px' />

          <IconContainer $scheme={scheme} $isOpen={isOpen}>
            <Icon
              name={isOpen ? 'caret-up-small' : 'caret-down-small'}
              color={scheme === 'dark' ? 'white' : 'charcoal'}
            />
          </IconContainer>
        </DisplayContainer>
      )}

      {isMobile && isOpen && (
        <Modal title='Select Country' onClose={handleClose} closeOnMask>
          <CountrySelectorList options={options} value={value} onChange={handleChange} />
        </Modal>
      )}
    </>
  )
}

export default CountrySelectorRenderer

const IconContainer = styled.div<{ $scheme: SchemeType; $isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  transition: ${({ theme }) => theme.transitions.background};
  background: ${({ theme, $scheme, $isOpen }) =>
    $isOpen ? theme.colors[INPUT_SCHEME[$scheme].buttonBackgroundColor] : 'transparent'};
  border-radius: 4px;
`

const DisplayContainer = styled.div<{ $scheme: SchemeType }>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 10px 7px 10px 15px;
  border-radius: 7px 0px 0px 7px;
  cursor: pointer;
  border-right: 1px solid
    ${({ theme, $scheme }) => ($scheme === 'dark' ? theme.colors.white10 : theme.colors.charcoal20)};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 13px 7px 13px 15px;
    height: 46px;
  }

  &:hover {
    ${IconContainer} {
      background: ${({ theme, $scheme }) => theme.colors[INPUT_SCHEME[$scheme].buttonBackgroundColor]};
    }
  }
`
